import React from 'react';

import WatchedIcon from '@atlaskit/icon/core/eye-open-filled';
import UnwatchedIcon from '@atlaskit/icon/core/eye-open';

type WatchIconProps = {
	isWatched: boolean;
};

export const WatchIcon = ({ isWatched }: WatchIconProps) => {
	return isWatched ? (
		<WatchedIcon label="" color="currentColor" />
	) : (
		<UnwatchedIcon label="" color="currentColor" />
	);
};
