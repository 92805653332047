/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import type { FC } from 'react';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';

import { ButtonItem } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';

import { getShortcutString, STAR_BUTTON_SHORTCUT } from '@confluence/shortcuts';

import type { MenuItemProps } from '../PageStar/PageStarMenuItem';
import { StarIcon } from '../StarIcon';

import type {
	SpaceStarMenuItemIsSpaceFavouritedQuery as SpaceStarMenuItemIsSpaceFavouritedQuery$data,
	SpaceStarMenuItemIsSpaceFavouritedQueryVariables as SpaceStarMenuItemIsSpaceFavouritedQuery$variables,
} from './__types__/SpaceStarMenuItemIsSpaceFavouritedQuery';
import type { SpaceStarProps } from './SpaceStar';
import { SpaceStar } from './SpaceStar';

const shortcutStyles = xcss({
	backgroundColor: 'color.background.neutral',
	color: 'color.text.accent.gray',
	paddingBlock: 'space.025',
	paddingInline: 'space.075',
	borderRadius: '2px',
	margin: 'space.025',
});

/**
 * Menu button that allows users to star or unstar a space.
 *
 * Renders as a menu button with an icon. Typically used in menus (e.g. Space actions menu, Space directory overflow menu)
 */
export const SpaceStarMenuItem: FC<MenuItemProps & SpaceStarProps> = ({
	isDisabled,
	className,
	shortcut,
	...props
}) => {
	const TooltipI18n = props.isStarred ? i18n.Unstar : i18n.Star;

	return (
		<SpaceStar {...props}>
			{({ toggle: toggleStar }) => (
				<ButtonItem
					testId="star-menu-item"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/no-unsafe-style-overrides
					className={className}
					isDisabled={isDisabled}
					iconBefore={<StarIcon isStarred={props.isStarred} spacing={props.spacing} />}
					onClick={toggleStar}
					iconAfter={shortcut && <Box xcss={shortcutStyles}>{shortcut}</Box>}
				>
					<FormattedMessage {...TooltipI18n} />
				</ButtonItem>
			)}
		</SpaceStar>
	);
};

type SpaceStarMenuItemWithQueryProps = {
	spaceKey: string;
};

// Space starring only works for view mode for space overviews.
// Starring on an edit space overview uses PageStar, not SpaceStar.
export const SpaceStarMenuItemWithQuery: FC<SpaceStarMenuItemWithQueryProps> = ({ spaceKey }) => {
	const { data: spaceFavouriteStatusData } = useQuery<
		SpaceStarMenuItemIsSpaceFavouritedQuery$data,
		SpaceStarMenuItemIsSpaceFavouritedQuery$variables
	>(
		gql`
			query SpaceStarMenuItemIsSpaceFavouritedQuery($spaceKey: String!) {
				space(key: $spaceKey) {
					id
					alias
					name
					key
					currentUser {
						isFavourited
					}
				}
			}
		`,
		{
			variables: {
				spaceKey,
			},
			fetchPolicy: 'cache-first',
		},
	);

	const isStarred = spaceFavouriteStatusData?.space?.currentUser?.isFavourited || false;
	const spaceName =
		spaceFavouriteStatusData?.space?.name ||
		spaceFavouriteStatusData?.space?.alias ||
		spaceFavouriteStatusData?.space?.key ||
		'';
	const spaceId = spaceFavouriteStatusData?.space?.id || '';

	return (
		<SpaceStarMenuItem
			isStarred={isStarred}
			spaceId={spaceId}
			spaceKey={spaceKey}
			spacing="none"
			spaceName={spaceName}
			shortcut={getShortcutString(STAR_BUTTON_SHORTCUT)}
			shouldShowFlags
			analytics={{
				attributes: {
					isContentWrapper: true, // as of now, SpaceStarMenuItemWithQuery is only called from object header overflow menu
					spaceId,
				},
				source: 'SpaceStarMenuItemOverflowMenu',
			}}
		/>
	);
};

const i18n = defineMessages({
	Star: {
		id: 'action-buttons.space.star.menu.item',
		defaultMessage: 'Star space',
		description:
			'Text for unfilled star menu item which indicates that the space is unstarred & stars the space when clicked',
	},
	Unstar: {
		id: 'action-buttons.space.unstar.menu.item',
		defaultMessage: 'Unstar space',
		description:
			'Text for filled star menu item which indicates that the space is starred & unstars the space when clicked',
	},
});
